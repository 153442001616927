export const googleAuthClientId = Object.freeze(
  process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID ??
    '563937062236-4nhc4m8krfp1rbdjmrbeknpb05ei57bk.apps.googleusercontent.com'
);

export const superUserGoogleTokenURL = Object.freeze(
  process.env.REACT_APP_SUPERUSER_GOOGLE_TOKEN_URL ??
    'https://auth.yellowdogsoftware.com/token/google-superuser'
);

export const defaultHeaders = Object.freeze({
  'Content-Type': 'application/json',
  'Access-Control-Allow-Credentials': 'true',
  'Access-Control-Allow-Origin': '*',
});

export const AppTargets = Object.freeze(
  (() => {
    try {
      return JSON.parse(process.env.REACT_APP_APP_TARGETS ?? '["ydmobile://auth"]');
    } catch (error) {
      return ['ydmobile://auth'];
    }
  })()
);
