import React, { useCallback, useEffect, useMemo, useState } from 'react';
import logo from './logo.png';
import logoWhite from './logo-white.png';
import './App.scss';
import {
  Button,
  Tile,
  Grid,
  Row,
  Column,
  TextInput,
  Form,
  Content,
  Modal,
  Loading,
} from 'carbon-components-react';
import {
  LoginType,
  useThemed,
  postSuccessfulLoginMessage,
  useSuperUserGoogleLogin,
  useLoginForm,
  getSuperUserToken,
  GoogleTokenResponse,
} from './utilities';
import { Copyright, Version } from './components';
import { Route, Routes, useSearchParams, useNavigate } from 'react-router-dom';

function Root() {
  const currentLogo = useThemed({ light: logo, dark: logoWhite });

  const [showModal, setShowModal] = useState(false);
  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);
  const showModalCb = useCallback(() => setShowModal(true), [setShowModal]);

  const [params] = useSearchParams();

  const superUserOnSuccessCallback = useCallback(
    (data: GoogleTokenResponse) =>
      postSuccessfulLoginMessage(data, params.get('target') || '', LoginType.SUPERUSER_GOOGLE),
    [params]
  );

  const [loginSU, suLoading] = useSuperUserGoogleLogin(superUserOnSuccessCallback, showModalCb);

  const { onSubmit, clientIdProps, usernameProps, passwordProps, loading, formState, error } =
    useLoginForm({
      onSubmit: postSuccessfulLoginMessage,
      onError: showModalCb,
    });

  return (
    <Content>
      <Modal
        modalHeading="Unable to Login"
        passiveModal
        open={showModal}
        color="red"
        onRequestClose={closeModal}
      >
        <p>{error?.message}</p>
      </Modal>
      <Grid className="container">
        <Row className="body">
          <Column sm={0} md={1} lg={4} />
          <Column>
            <Form onSubmit={onSubmit}>
              <Tile className="margin-10-padding-10">
                <div className="app-logo-container">
                  <img src={currentLogo} className="App-logo" alt="logo" />
                </div>
                <h1 className="centered">Log In</h1>
                <TextInput id="clientId" labelText="Client ID" size="sm" {...clientIdProps} />
                <TextInput id="username" labelText="Username" size="sm" {...usernameProps} />
                <TextInput
                  id="Password"
                  labelText="Password"
                  size="sm"
                  type="password"
                  {...passwordProps}
                />
                <Button
                  size="sm"
                  kind="primary"
                  type="submit"
                  disabled={!formState.isValid || loading || suLoading}
                  isExpressive
                >
                  Submit
                </Button>
                <p className="errorMessage">{error?.message}</p>
              </Tile>
            </Form>
          </Column>
          <Column sm={0} md={1} lg={4} />
        </Row>
        <Row className="spacer-row" />
      </Grid>
      <div className="footer">
        <Grid>
          <Row>
            <Column sm={0} md={1} lg={4} />
            <Column>
              <div className="centered">
                <Version />
              </div>
            </Column>
            <Column>
              <div className="centered">
                <Copyright />
              </div>
            </Column>
            <Column>
              <div className="centered">
                <Button kind="ghost" size="sm" onClick={() => loginSU()}>
                  Support Login
                </Button>
              </div>
            </Column>
            <Column sm={0} md={1} lg={4} />
          </Row>
        </Grid>
      </div>
    </Content>
  );
}

interface GoogleAuthSearchParams {
  code: string;
  scope: string;
  authuser: string;
  hd: string;
  prompt: string;
  state: { target: string } & { [key: string]: any };
}

function SuperUserGoogleAuth() {
  const [searchParams] = useSearchParams();
  const values = useMemo(() => {
    try {
      const params = Object.fromEntries(searchParams) as any as Omit<
        GoogleAuthSearchParams,
        'state'
      > & { state: string };

      return { ...params, state: JSON.parse(atob(params.state)) } as GoogleAuthSearchParams;
    } catch (error) {
      return null;
    }
  }, [searchParams]);

  const code = values?.code;
  const target = values?.state?.target;

  const [isModalOpen, setShowModal] = useState(false);
  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);
  const showModal = useCallback(() => setShowModal(true), [setShowModal]);

  const navigate = useNavigate();

  const goBack = useCallback(() => {
    closeModal();
    navigate(`/?target=${target}`);
  }, [closeModal, target]);

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (code && target) {
      setError(null);
      getSuperUserToken(code)
        .then((data) => postSuccessfulLoginMessage(data, target, LoginType.SUPERUSER_GOOGLE))
        .catch((err) => {
          setError(err.message);
          showModal();
        });
    }
  }, [code, target]);

  return (
    <Content>
      <Modal
        modalHeading="Unable to Login"
        passiveModal
        open={isModalOpen}
        color="red"
        onRequestClose={goBack}
      >
        <p>{error}</p>
      </Modal>
      <Loading />
    </Content>
  );
}

function App() {
  return (
    <Routes>
      <Route path="/" element={<Root />} />
      <Route path="/auth/google/callback" element={<SuperUserGoogleAuth />} />
    </Routes>
  );
}

export default App;
