import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import { useCallback, useMemo, useState } from 'react';
import { defaultHeaders, superUserGoogleTokenURL } from './constants';
import { useSearchParams } from 'react-router-dom';

export interface GoogleTokenResponse {
  idToken: string;
  accessToken: string;
  refreshToken?: string;
  expiresIn: number;
  expires: Date;
}

type GoogleResponse = Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>;

export const getSuperUserToken = async (code: string) => {
  const resp = await fetch(superUserGoogleTokenURL, {
    body: JSON.stringify({ code, redirectURI: `${window.location.origin}/auth/google/callback` }),
    method: 'POST',
    headers: defaultHeaders,
  });

  if (resp.ok) {
    return (await resp.json()) as GoogleTokenResponse;
  }

  throw new Error('Failed to get super user token');
};

export const useSuperUserGoogleLogin = (
  onSuccessCb?: (resp: GoogleTokenResponse) => void,
  onErrorCb?: (err: any) => void
) => {
  const [running, setRunning] = useState(false);
  const onSuccess = useCallback(
    async (response: GoogleResponse) => {
      setRunning(true);
      try {
        const resp = await fetch(superUserGoogleTokenURL, {
          body: JSON.stringify({
            code: response.code,
            redirectURI: `${window.location.origin}/auth/google/callback`,
          }),
          method: 'POST',
          headers: defaultHeaders,
        });

        if (resp.ok) {
          const data: GoogleTokenResponse = await resp.json();
          onSuccessCb?.(data);
        } else {
          onErrorCb?.(resp);
        }
      } catch (error) {
        onErrorCb?.(error);
      } finally {
        setRunning(false);
      }
    },
    [setRunning, onSuccessCb, onErrorCb]
  );

  const onError = useCallback(
    (err: any) => {
      setRunning(false);
      onErrorCb?.(err);
    },
    [onErrorCb, setRunning]
  );

  const [params] = useSearchParams();

  const target = useMemo(() => params.get('target'), [params]);

  const state = useMemo(() => btoa(JSON.stringify({ target })), [target]);

  const login = useGoogleLogin({
    flow: 'auth-code',
    include_granted_scopes: false,
    hosted_domain: 'yellowdogsoftware.com',
    redirect_uri: `${window.location.origin}/auth/google/callback`,
    state,
    ux_mode: 'redirect',
    onSuccess,
    onError,
  });

  return [login, running] as [typeof login, boolean];
};
