import { AppTargets } from './constants';
import { LoginType } from './enums';

export const postSuccessfulLoginMessage = (
  authResponse: any,
  target: string,
  loginType: LoginType = LoginType.USER
) => {
  if (target) {
    const message = {
      ...authResponse,
      target,
      loginType,
    };
    // @ts-ignore
    // react native webview support
    const reactNativePostMessage = window?.ReactNativeWebView?.postMessage as
      | ((msg: string) => void)
      | undefined;
    if (loginType === LoginType.SUPERUSER_GOOGLE && AppTargets.includes(target)) {
      const { clients, ...params } = message;
      const queryParams = new URLSearchParams(params);
      const url = new URL(`${target}?${queryParams}`);
      window.location.href = url.toString();
    } else if (loginType == LoginType.USER && reactNativePostMessage) {
      reactNativePostMessage(JSON.stringify(message));
    } else if (window?.opener?.postMessage) {
      window.opener.postMessage(message, target);
      window.close();
    } else if (window?.parent?.postMessage) {
      window?.parent?.postMessage(message, target);
    }
  } else {
    throw new Error('');
  }
};
