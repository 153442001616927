import { useCallback, useEffect, useMemo } from 'react';
import { TextInputProps } from 'carbon-components-react';
import {
  useForm,
  SubmitHandler,
  FormState,
  UseFormRegister,
  FieldValues,
  Path,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDingoLogin } from '../dingo';
import { LoginType } from './enums';
import { useSearchParams } from 'react-router-dom';
import { postSuccessfulLoginMessage } from './post-successful-login-message';

interface Inputs {
  clientId: string;
  username: string;
  password: string;
}

const schema = yup
  .object({
    clientId: yup.string().required(),
    username: yup.string().min(2).required(),
    password: yup.string().min(2).required(),
  })
  .required();

const resolver = yupResolver(schema);

const useFormInputProps = <T extends FieldValues>(
  { errors, isValid, isDirty }: FormState<T>,
  key: Path<T>,
  register: UseFormRegister<T>
): Pick<TextInputProps, 'invalid' | 'invalidText'> => {
  const registeredProps = useMemo(() => register(key), [register, key]);
  return useMemo(() => {
    const invalidText = (errors as any)?.[key]?.message as string | null | undefined;
    return { invalid: isDirty && !!invalidText, invalidText, ...registeredProps };
  }, [errors, isValid, isDirty, registeredProps]);
};

interface UseLoginFormParams {
  onSubmit: typeof postSuccessfulLoginMessage;
  onError: (error: any) => void;
}

export const useLoginForm = ({ onSubmit, onError }: UseLoginFormParams) => {
  const { register, handleSubmit, formState, setValue } = useForm<Inputs>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver,
  });

  const [login, { loading, error, called }] = useDingoLogin();

  useEffect(() => {
    if (error) {
      onError(error);
    }
  }, [error]);

  const [searchParams] = useSearchParams();
  const clientId = useMemo(() => searchParams.get('client_id') ?? '', [searchParams]);
  const target = useMemo(() => searchParams.get('target') ?? '', [searchParams]);

  useEffect(() => {
    setValue('clientId', clientId);
  }, [clientId]);

  const clientIdProps: Pick<TextInputProps, 'invalid' | 'invalidText' | 'disabled'> =
    useFormInputProps<Inputs>(formState, 'clientId', register);
  clientIdProps.disabled = !!clientId;
  const usernameProps = useFormInputProps<Inputs>(formState, 'username', register);
  const passwordProps = useFormInputProps<Inputs>(formState, 'password', register);

  const onSubmitCallback: SubmitHandler<Inputs> = useCallback(
    async (variables) => {
      const { data, errors } = await login({ variables });
      if (!errors?.length) {
        onSubmit(data?.login?.result, target, LoginType.USER);
      }
    },
    [login, onsubmit, target]
  );

  const onSubmitCb = useMemo(() => handleSubmit(onSubmitCallback), [handleSubmit]);

  return {
    onSubmit: onSubmitCb,
    clientIdProps,
    usernameProps,
    passwordProps,
    loading,
    called,
    error,
    formState,
  };
};
